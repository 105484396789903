<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="CAPACITACIÓN"
            image-name="Capacitacion.png"
        ></Screen_Header>

        <!-- REGULATIONS DESCRIPTION ROW -->
        <Content_Row>
            <v-row>
                <!-- ISO 9001 COLUMN -->
                <Content_Column>
                    <!-- ISO 9001 SUBHEADER ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 noSpace">
                        <!-- ISO 9001 SUBTITLE -->
                        <v-col cols="12" md="6" class="noSpace">
                            <Subtitle_Text>
                                ISO 9001
                            </Subtitle_Text>
                        </v-col>

                        <!-- ISO 9001 LOG0 -->
                        <v-col cols="12" md="6" align="center" class="noSpace">
                            <Regulation_Image
                                image="ISO 9001.png"
                            ></Regulation_Image>
                        </v-col>
                    </v-row>

                    <!-- ISO 9001 TEXT ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                        <ul>
                            <li>
                                <Paragraph_Text>
                                    Interpretación de la ISO 9001:2015.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Gestión de Riesgos para ISO 9001:2015.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Mapeo de procesos.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Documentación de un Sistema de Gestión con base en la norma ISO 10013:2021.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Indicadores para un Sistema de Gestión de la Calidad.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Herramientas para la mejora continua y solución de no conformidades.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Auditor interno para un Sistema de Gestión de la Calidad con base en la norma ISO 19011: 2018.
                                </Paragraph_Text>
                            </li>
                        </ul>

                        <Paragraph_Text>
                            <br><br>Solicítanos información específica del tema que necesites para desarrollar o implementar tu SGC
                            con ISO 9001:2015.
                        </Paragraph_Text>
                    </v-row>
                </Content_Column>

                <!-- ISO 14001 COLUMN -->
                <Content_Column class="mt-lg-0 mt-12">
                    <!-- ISO 14001 SUBHEADER ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 noSpace">
                        <!-- ISO 14001 SUBTITLE -->
                        <v-col cols="12" md="6" class="noSpace">
                            <Subtitle_Text>
                                ISO 14001
                            </Subtitle_Text>
                        </v-col>

                        <!-- ISO 14001 LOG0 -->
                        <v-col cols="12" md="6" align="center" class="noSpace">
                            <Regulation_Image
                                image="ISO 14001.png"
                            ></Regulation_Image>
                        </v-col>
                    </v-row>

                    <!-- ISO 14001 TEXT ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                        <ul>
                            <li>
                                <Paragraph_Text>
                                    Interpretación de la ISO 14001:2015 Sistema de Gestión Ambiental.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Identificación, análisis y capacitación, evaluación de aspectos e impactos ambientales.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Perspectiva del ciclo de vida.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Legislación Ambiental.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Indicadores de desempeño ambiental con base en la norma ISO 14031.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Auditor interno para un sistema de gestión ambiental con base en la norma ISO 19011: 2018
                                </Paragraph_Text>
                            </li>
                        </ul>
                    </v-row>
                </Content_Column>
            </v-row>
        </Content_Row>

        <!-- SCREEN SUB HEADER -->
        <Screen_Header
            image-name="Capacitacion 2.png"
        ></Screen_Header>

        <!-- REGULATIONS #2 DESCRIPTION ROW -->
        <Content_Row>
            <v-row>
                <!-- ISO 17025 COLUMN -->
                <Content_Column>
                    <!-- ISO 17025 SUBHEADER ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 noSpace">
                        <!-- ISO 17025 SUBTITLE -->
                        <v-col cols="12" md="6" class="noSpace">
                            <Subtitle_Text>
                                ISO 17025
                            </Subtitle_Text>
                        </v-col>

                        <!-- ISO 17025 LOG0 -->
                        <v-col cols="12" md="6" align="center" class="noSpace">
                            <Regulation_Image
                                image="ISO 17025.png"
                            ></Regulation_Image>
                        </v-col>
                    </v-row>

                    <!-- ISO 17025 TEXT ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                        <ul>
                            <li>
                                <Paragraph_Text>
                                    Interpretación de la ISO 17025:2017
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Documentación de un SGC con base en la 17025.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Verificación y validación de métodos analíticos.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Cuantificación de incertidumbre en métodos analíticos.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Reglas de decisión.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Ensayos de aptitud.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Auditor interno para un sistema de gestión de la calidad para laboratorios con base en la norma ISO 19011: 2018.
                                </Paragraph_Text>
                            </li>
                        </ul>
                    </v-row>
                </Content_Column>

                <!-- ISO 45001 COLUMN -->
                <Content_Column class="mt-lg-0 mt-12">
                    <!-- ISO 45001 SUBHEADER ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 noSpace">
                        <!-- ISO 45001 SUBTITLE -->
                        <v-col cols="12" md="6" class="noSpace">
                            <Subtitle_Text>
                                ISO 45001
                            </Subtitle_Text>
                        </v-col>

                        <!-- ISO 45001 LOG0 -->
                        <v-col cols="12" md="6" align="center" class="noSpace">
                            <Regulation_Image
                                image="ISO 45001.png"
                            ></Regulation_Image>
                        </v-col>
                    </v-row>

                    <!-- ISO 45001 TEXT ROW -->
                    <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                        <ul>
                            <li>
                                <Paragraph_Text>
                                    Interpretación de la ISO 45001:2018.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Identificación de peligros y evaluación de riesgos de Seguridad y Salud en el Trabajo
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Auditor interno para un sistema de gestión de Seguridad y Salud en el trabajo con
                                    base en la norma ISO 19011: 2018.
                                </Paragraph_Text>
                            </li>
                            <li>
                                <Paragraph_Text>
                                    Legislación en SST.
                                </Paragraph_Text>
                            </li>
                        </ul>
                    </v-row>
                </Content_Column>
            </v-row>
        </Content_Row>

        <!-- CONTACT BUTTON -->
        <SingleButton_Row
            button-label="Contáctanos"
            @button-click="goToNextRoute"
            class="mt-12"
        ></SingleButton_Row>
    </div>
</template>

<script>
import Content_Row from "@/components/sections/content/Content_Row";
import Screen_Header from "@/components/sections/headers/Screen_Header";
import Subtitle_Text from "@/components/texts/Subtitle_Text";
import Paragraph_Text from "@/components/texts/Paragraph_Text";
import SingleButton_Row from "@/components/buttons/SingleButton_Row";
import Regulation_Image from "@/components/images/Regulation_Image";
import Content_Column from "@/components/sections/content/Content_Column";

export default {
    name: "Training_Screen",
    components: {
        Screen_Header,
        Subtitle_Text,
        Content_Row,
        Paragraph_Text,
        SingleButton_Row,
        Regulation_Image,
        Content_Column,
    },
    methods: {
        goToNextRoute() {
            this.$router.push('/contacto');
        }
    }
}
</script>

<style scoped>

</style>