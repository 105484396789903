<template>
    <v-img
        :src="require('./../../assets/regulations/' + this.image)"
        max-width="225"
        class="mt-4 mt-lg-0"
        contain
    ></v-img>
</template>

<script>
export default {
    name: "Regulation_Image",
    props: {
        image: String,
    }
}
</script>

<style scoped>

</style>