<template>
    <v-row align="center" justify="center">
        <v-img
            :src="require('../../../assets/headers/' + this.deviceType + '/' + this.imageName)"
            max-height="325"
            min-height="275"
        >
            <v-overlay
                absolute
                color="#000"
                opacity="0.25"
            >
                <v-container fluid class="fill-height" v-if="headerText !== null">
                    <v-row align="center" justify="center">
                        <v-col align="center" justify="center">
                            <Title_Text>
                                {{ headerText }}
                            </Title_Text>
                        </v-col>
                    </v-row>
                </v-container>
            </v-overlay>
        </v-img>
    </v-row>
</template>

<script>
import Title_Text from "@/components/texts/Title_Text";

export default {
    props: {
        headerText: String,
        imageName: String,
    },
    name: "Screen_Header",
    components: {
        Title_Text,
    },
    computed: {
        deviceType() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'mobile'
                case 'sm': return 'mobile'
                case 'md': return 'mobile'
                case 'lg': return 'desktop'
                case 'xl': return 'desktop'
                default: return 'desktop'
            }
        }
    },
}
</script>

<style scoped>

</style>