<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="Consultoría Ambiental"
            image-name="Inicio.png"
        ></Screen_Header>

        <Content_Row>
            <!-- SLOGAN ROW -->
            <v-row align="center" justify="center" class="px-lg-16">
                <Subtitle_Text style="text-align: center !important;">
                    PUNTLI  EL ENLACE ENTRE LA INDUSTRIA, EL CUIDADO AMBIENTAL, LA SEGURIDAD LABORAL Y LA CALIDAD
                </Subtitle_Text>
            </v-row>

            <!-- COMPANY INTRO ROW -->
            <v-row align="center" justify="center" class="px-lg-16 mt-8">
                <!-- COMPANY FULL LOGO -->
                <v-col cols="12" md="4" class="pa-8">
                    <v-img
                        src="./../assets/brand/Logo Puntli.png"
                    ></v-img>
                </v-col>
                <!-- COMPANY FULL DESCRIPTION -->
                <v-col cols="12" md="8" class="pr-lg-16">
                    <Paragraph_Text>
                        Somos una empresa enfocada en las áreas de Sistemas de Gestión de Calidad, Medio Ambiente,
                        Seguridad y Salud en el Trabajo, Capacitación en Recursos Humanos, Administración de Laboratorios
                        de Ensayo y Clínicos, entre otras.
                    </Paragraph_Text>
                </v-col>
            </v-row>
        </Content_Row>

        <!-- SCREEN HEADER -->
        <Screen_Header
            image-name="Inicio 2.png"
        ></Screen_Header>

        <Content_Row>
            <!-- COMPANY INTRO ROW -->
            <v-row align="center" justify="center" class="px-lg-16">
                <!-- COMPANY FULL DESCRIPTION -->
                <Paragraph_Text>
                    Nuestro objetivo es proporcionar servicios de asesoría en las áreas de calidad, medio ambiente e
                    higiene y seguridad, contribuyendo al desarrollo y cumplimiento legal de nuestros clientes
                    satisfaciendo sus necesidades y expectativas.
                </Paragraph_Text>
            </v-row>

            <!-- CONTACT BUTTON -->
            <SingleButton_Row
                button-label="Contáctanos"
                @button-click="goToNextRoute"
                class="mt-16"
            ></SingleButton_Row>
        </Content_Row>
    </div>
</template>

<script>
import Content_Row from "@/components/sections/content/Content_Row";
import Screen_Header from "@/components/sections/headers/Screen_Header";
import Subtitle_Text from "@/components/texts/Subtitle_Text";
import Paragraph_Text from "@/components/texts/Paragraph_Text";
import SingleButton_Row from "@/components/buttons/SingleButton_Row";

export default {
    name: "Home_Screen",
    components: {
        Screen_Header,
        Subtitle_Text,
        Content_Row,
        Paragraph_Text,
        SingleButton_Row,
    },
    methods: {
        goToNextRoute() {
            this.$router.push('/contacto');
        }
    }
}
</script>

<style scoped>

</style>