<template>
    <v-col align-self="start" class="px-4 noSpace" :cols="this.deviceType === 'desktop' ? '6' : '12'">
        <slot />
    </v-col>
</template>

<script>
export default {
    name: "Content_Column",
    computed: {
        deviceType() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'mobile'
                case 'sm': return 'mobile'
                case 'md': return 'mobile'
                case 'lg': return 'desktop'
                case 'xl': return 'desktop'
                default: return 'desktop'
            }
        }
    },
}
</script>

<style scoped>

</style>