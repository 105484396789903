<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="SEGURIDAD Y SALUD EN EL TRABAJO"
            image-name="SST.png"
        ></Screen_Header>

        <Content_Row>
            <!-- SECURITY SUBHEADER ROW -->
            <v-row align="center" justify="center" class="px-lg-8 noSpace">
                <!-- SCREEN SUBTITLE -->
                <v-col cols="12" md="6" class="noSpace">
                    <Subtitle_Text>
                        Sistemas de Gestión de Seguridad y Salud en el Trabajo
                    </Subtitle_Text>
                </v-col>

                <!-- ISO 45001 LOG0 -->
                <v-col cols="12" md="6" align="center" class="noSpace">
                    <Regulation_Image
                        image="ISO 45001.png"
                    ></Regulation_Image>
                </v-col>
            </v-row>

            <!-- SECURITY TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                <Content_Column>
                    <Paragraph_Text>
                        La certificación con la norma ISO 45001 te permite conocer y cuidar todo lo relacionado con tus
                        procesos y la relación con la Seguridad y Salud en el Trabajo, asegurando que el personal se
                        encuentre en las condiciones óptimas para desarrollar sus actividades, optimizando los recursos
                        humanos y económicos de la empresa. <br><br>
                    </Paragraph_Text>
                </Content_Column>
                <Content_Column>
                    <Paragraph_Text>
                        Para certificarse se requiere:
                    </Paragraph_Text>
                    <ul>
                        <li><Paragraph_Text>
                            Desarrollo, implementación y asesoría en Sistemas de Gestión de Seguridad y Salud en el Trabajo (SST) de empresas ISO 45001:2018.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Apoyo para implementar el SST.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Asesoría en un punto específico del SST.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Realizar una auditoría a tu SST.
                        </Paragraph_Text></li>
                    </ul>

                </Content_Column>
            </v-row>
        </Content_Row>

        <!-- SCREEN SUB HEADER -->
        <Screen_Header
            image-name="SST 2.png"
        ></Screen_Header>

        <Content_Row>
            <!-- SECURITY SUBHEADER ROW -->
            <v-row align="center" justify="center" class="px-lg-8 noSpace">
                <!-- SCREEN SUBTITLE -->
                <v-col cols="12" md="6" class="noSpace">
                    <Subtitle_Text>
                        Trámites para Ambiente laboral
                    </Subtitle_Text>
                </v-col>

                <!-- NOM LOG0 -->
                <v-col cols="12" md="6" align="center" class="noSpace">
                    <Regulation_Image
                        image="NOM.png"
                    ></Regulation_Image>
                </v-col>
            </v-row>

            <!-- QUALITY TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-12">
                <!-- LEFT COLUMN -->
                <Content_Column>
                    <ul>
                        <li>
                            <Paragraph_Text>
                                Determinación del grado de riesgo de incendio en el centro de trabajo. NOM-002-STPS.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Grado de riesgo potencial generado por la maquinaria y equipo NOM-004- STPS.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Grado de riesgo potencial generado por las sustancias químicas peligrosas NOM-005-STPS.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Cualquier otra Norma de STPS relacionada con tu empresa.
                            </Paragraph_Text>
                        </li>
                    </ul>
                </Content_Column>

                <!-- RIGHT COLUMN -->
                <Content_Column>
                    <ul>
                        <li>
                            <Paragraph_Text>
                                Análisis de riesgos generados por el manejo de materiales NOM-006-STPS.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Análisis de riesgos para determinar el equipo de protección personal NOM-017-STPS.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Diagnóstico de acuerdo con la STPS.
                            </Paragraph_Text>
                        </li>
                    </ul>
                </Content_Column>
            </v-row>

            <!-- COMPANY INTRO ROW -->
            <v-row align="center" justify="center" class="px-lg-16 mt-16">
                <!-- COMPANY FULL DESCRIPTION -->
                <Paragraph_Text  class="px-lg-16" style="text-align: center;">
                    Nuestro equipo de expertos calificados está listo para ayudarte a implementar, revisar y certificar
                    tus Sistemas de Gestión de Seguridad y Salud en el Trabajo.
                </Paragraph_Text>
            </v-row>

            <!-- CONTACT BUTTON -->
            <SingleButton_Row
                button-label="Contáctanos"
                @button-click="goToNextRoute"
                class="mt-12"
            ></SingleButton_Row>
        </Content_Row>
    </div>
</template>

<script>
import Content_Row from "@/components/sections/content/Content_Row";
import Screen_Header from "@/components/sections/headers/Screen_Header";
import Subtitle_Text from "@/components/texts/Subtitle_Text";
import Paragraph_Text from "@/components/texts/Paragraph_Text";
import SingleButton_Row from "@/components/buttons/SingleButton_Row";
import Regulation_Image from "@/components/images/Regulation_Image";
import Content_Column from "@/components/sections/content/Content_Column";

export default {
    name: "Security_Screen",
    components: {
        Screen_Header,
        Subtitle_Text,
        Content_Row,
        Paragraph_Text,
        SingleButton_Row,
        Regulation_Image,
        Content_Column,
    },
    methods: {
        goToNextRoute() {
            this.$router.push('/contacto');
        }
    }
}
</script>

<style scoped>

</style>