<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="CONTACTO"
            image-name="Contacto.png"
        ></Screen_Header>

        <v-row align="start" justify="center" class="px-lg-16 px-4">
            <!-- CONTACT FORM COLUMN -->
            <v-col cols="12" md="6">
                <v-container ref="form" class="fill-height pt-6">
                    <v-row align="center" class="pb-10 pt-2 mx-1 lightGreen" justify="center">
                        <v-col>
                            <!-- NAME TEXT FIELD -->
                            <v-text-field
                                @keyup.enter.exact="submitContactFormRequest"
                                :rules="[rules.required]"
                                class="mt-8 mx-4"
                                color="primary"
                                v-model="name"
                                label="Nombre"
                                ref="name"
                                outlined
                                dense
                            ></v-text-field>

                            <!-- EMAIL TEXT FIELD -->
                            <v-text-field
                                @keyup.enter.exact="submitContactFormRequest"
                                :rules="[rules.required, rules.email]"
                                class="mt-0 mx-4"
                                color="primary"
                                v-model="email"
                                label="Mail"
                                ref="email"
                                outlined
                                dense
                            ></v-text-field>

                            <!-- PHONE TEXT FIELD -->
                            <v-text-field
                                @keyup.enter.exact="submitContactFormRequest"
                                :rules="[rules.required, rules.phoneNumber]"
                                class="mt-0 mx-4"
                                label="Teléfono"
                                v-model="phone"
                                color="primary"
                                ref="phone"
                                outlined
                                dense
                            ></v-text-field>

                            <!-- MESSAGE TEXT FIELD -->
                            <v-textarea
                                @keyup.enter.exact="submitContactFormRequest"
                                :rules="[rules.required]"
                                v-model="message"
                                class="mt-0 mx-4"
                                label="Mensaje"
                                color="primary"
                                ref="message"
                                no-resize
                                outlined
                                dense
                            ></v-textarea>

                            <!-- FORM SUBMISSION BUTTON -->
                            <v-row align="center" justify="center" class="px-16 mt-4">
                                <Block_Button
                                    button-label="Enviar"
                                    @button-click="submitContactFormRequest"
                                    @keyup.enter.exact="submitContactFormRequest"
                                ></Block_Button>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <!-- CONTACT INFO COLUMN -->
            <v-col align="center" cols="12" md="6">
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="8">
                            <Icon_Button
                                :links="locationData"
                                icon="location_on"
                            ></Icon_Button>
                        </v-col>
                    </v-row>

                    <v-row align="start" justify="center">
                        <v-col cols="12" md="4">
                            <Icon_Button
                                :links="phoneData"
                                icon="phone"
                            ></Icon_Button>
                        </v-col>
                        <v-col cols="12" md="4">
                            <Icon_Button
                                :links="mailData"
                                icon="email"
                            ></Icon_Button>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-container>
                                <font-awesome-icon
                                    class="mb-5"
                                    icon="fa-brands fa-whatsapp"
                                    style="color: #3DAE2B; font-size: x-large; height: 38px"
                                />

                                <v-row
                                    align="center" justify="center"
                                >
                                    <a
                                        :href="whatsAppData[0].url"
                                        target="_blank"
                                    >
                                        <Paragraph_Text class="icon-button-text">
                                            {{ whatsAppData[0].text }}
                                        </Paragraph_Text>
                                    </a>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row align="center" justify="center">
                    <iframe
                        allowfullscreen=""
                        class="locationMap"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5235.482705937384!2d-99.11107475917127!3d19.249458217727472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0152618cc61f%3A0xf0226d6b890fa157!2sCam.%20Real%20a%20San%20Mateo%20119%2C%20Xochimilco%2C%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1658452921398!5m2!1ses-419!2smx"
                    ></iframe>
                </v-row>
            </v-col>
        </v-row>

        <!-- FORM ERROR BOTTOM SHEET -->
        <v-bottom-sheet v-model="openErrorBottomSheet">
            <v-sheet
                class="text-center"
                height="150px"
            >
                <v-btn
                    class="mt-6"
                    color="primary"
                    text
                    @click="openErrorBottomSheet = !openErrorBottomSheet"
                >
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Debes llenar todos los campos del formulario correctamente.
                </div>
            </v-sheet>
        </v-bottom-sheet>


        <!-- THANKS BOTTOM SHEET -->
        <v-bottom-sheet v-model="thanksBottomSheet">
            <v-sheet
                class="text-center"
                height="150px"
            >
                <v-btn
                    class="mt-6"
                    color="primary"
                    text
                    @click="thanksBottomSheet = !thanksBottomSheet"
                >
                    CERRAR
                </v-btn>
                <div class="py-3">
                    Gracias por compartirnos tus datos, nos pondremos en contacto contigo.
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import Screen_Header from "@/components/sections/headers/Screen_Header";
import axios from "axios"
import Icon_Button from "@/components/buttons/Icon_Button";
import Block_Button from "@/components/buttons/Block_Button";
import Paragraph_Text from "@/components/texts/Paragraph_Text";

export default {
    name: "Contact_Screen",
    components: {
        Screen_Header,
        Icon_Button,
        Block_Button,
        Paragraph_Text,
    },
    data() {
        return {
            name: null,
            email: null,
            phone: null,
            message: null,
            formHasErrors: true,
            thanksBottomSheet: false,
            openErrorBottomSheet: false,
            rules: {
                required: value => !!value || 'Este campo es obligatorio.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Formato de e-mail inválido.'
                },
                phoneNumber: value => {
                    const pattern = /\d{10}/
                    return pattern.test(value) || 'Teléfono debe tener únicamente 10 dígitos'
                }
            },
            locationData: [
                {
                    url: 'https://goo.gl/maps/5nyf6u4fisGYrh2J9',
                    text: 'Camino a San mateo 119 Col. Tablas de San Lorenzo, Alcaldía Xochimilco, Ciudad de México, C.P. 16090',
                },
            ],
            phoneData: [
                {
                    url: 'tel:5556755300',
                    text: '5556755300',
                },
            ],
            mailData: [
                {
                    url: 'mailto:contacto@puntli.com',
                    text: 'contacto@puntli.com',
                },
            ],
            whatsAppData: [
                {
                    url: 'https://wa.me/5215556755300',
                    text: '5556755300',
                },
            ]
        }
    },
    computed: {
        form() {
            return {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message,
            }
        },
    },
    methods: {
        formValidation() {
            this.formHasErrors = true;

            console.log("Validating form...");

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) this.formHasErrors = false

                this.$refs[f].validate(false)
            })
        },

        async submitContactFormRequest() {
            this.formValidation();

            if (this.formHasErrors === true) {
                const formObject = {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    message: this.message
                }

                await axios.post('http://localhost:5001/puntli/us-central1/sendContactFormViaEmail', formObject);

                this.thanksBottomSheet = !this.thanksBottomSheet;

            } else {
                this.openErrorBottomSheet = !this.openErrorBottomSheet;
            }
        },

        clearForm() {
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
        }
    }
}
</script>

<style scoped>
.locationMap {
    border:0;
    height: 35vh;
    margin-top: 20px;
    width: 90%;
}

p, a {
    color: #888888 !important;
    font-size: 0.9em;
}

p:hover, a:hover {
    color: #0B6937 !important;
    text-decoration: underline !important;
}


.icon-button-text, a {
    color: #888888 !important;
    font-size: 1em;
    text-decoration: none !important;
}

.icon-button-text:hover {
    color: #0B6937 !important;
    font-size: 1em;
    text-decoration: underline !important;
}

</style>