<template>
    <div class="title-text baloo text-uppercase">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Title_Text",
}
</script>

<style scoped>
.title-text {
    color: #FFFFFF;
    font-size: 3em;
}
</style>