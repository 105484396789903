<template>
    <v-hover
        v-slot="{ hover }"
    >
        <v-btn
            @keyup.enter.exact="runParentFunctionRequest"
            @click="runParentFunctionRequest"
            :elevation="hover ? 6 : 1"
            class="balooBold"
            color="primary"
            rounded
            block
        >
            {{ buttonLabel }}
        </v-btn>
    </v-hover>
</template>

<script>
export default {
    props: {
        buttonLabel: String,
    },
    name: "Block_Button",
    methods: {
        runParentFunctionRequest() {
            this.$emit('button-click');
        },
    }
}
</script>

<style scoped>

</style>