<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="Medio Ambiente"
            image-name="Medio ambiente.png"
        ></Screen_Header>

        <Content_Row>
            <!-- ENVIRONMENT SUBHEADER ROW -->
            <v-row align="center" justify="center" class="px-lg-8 noSpace">
                <!-- SCREEN SUBTITLE -->
                <v-col cols="12" md="6" class="noSpace">
                    <Subtitle_Text>
                        Sistemas de Gestión Ambiental para las empresas.
                    </Subtitle_Text>
                </v-col>

                <!-- ISO 14001 LOG0 -->
                <v-col cols="12" md="6" align="center" class="noSpace">
                    <Regulation_Image
                        image="ISO 14001.png"
                    ></Regulation_Image>
                </v-col>
            </v-row>

            <!-- ENVIRONMENT TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                <Content_Column>
                    <Paragraph_Text>
                        La certificación con la norma ISO 14001 te permite conocer ambientalmente a tu organización,
                        minimizar sus impactos ambientales, optimizar tus operaciones y recursos incluyendo los
                        económicos. <br><br>
                    </Paragraph_Text>
                    <Paragraph_Text>
                        Organizar de manera eficiente una empresa en la parte medioambiental es posible, solo debemos
                        enfocarnos en los aspectos ambientales de los procesos y en cómo optimizarlos.
                    </Paragraph_Text>
                </Content_Column>
                <Content_Column>
                    <Paragraph_Text>
                        Para certificarse se requiere:
                    </Paragraph_Text>
                    <ul>
                        <li><Paragraph_Text>
                            Desarrollo, implementación y asesoría en Sistemas de Gestión Ambiental de empresas ISO
                            14001:2015.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Apoyo para implementar el SGA.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Asesoría en un punto específico del SGA.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Realizar una auditoría a tu SGA.
                        </Paragraph_Text></li>
                    </ul>

                </Content_Column>
            </v-row>
        </Content_Row>

        <!-- SCREEN HEADER -->
        <Screen_Header
            image-name="Medio ambiente 2.png"
        ></Screen_Header>

        <Content_Row>
            <!-- ENVIRONMENT TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-12">
                <!-- LEFT COLUMN -->
                <Content_Column>
                    <Subtitle_Text class="mb-4">
                        Auditoría ambiental
                    </Subtitle_Text>
                    <ul>
                        <li>
                            <Paragraph_Text>
                                La auditoría ambiental permite a tu empresa evidenciar, armonizar con la legislación,
                                pero lo más importante el manejo óptimo de los recursos naturales en tu empresa y
                                beneficios económicos con este manejo.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Obtención de Certificación como Industria Limpia, Calidad Ambiental o Calidad
                                Ambiental Turística.
                            </Paragraph_Text>
                        </li>
                    </ul>

                    <Subtitle_Text class="mb-4 mt-12">
                        Estudios especiales
                    </Subtitle_Text>
                    <ul>
                        <li>
                            <Paragraph_Text>
                                Manifestaciones de impacto ambiental, local y federal.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Estudio de riesgo ambiental, local y federal.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Estudios de impacto urbano.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Programa de prevención de accidentes.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Programa de protección civil.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Impacto vial.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Informe previo.
                            </Paragraph_Text>
                        </li>
                    </ul>
                </Content_Column>

                <!-- RIGHT COLUMN -->
                <Content_Column>
                    <Subtitle_Text class="mb-4 mt-lg-0 mt-12">
                        Trámites ante dependencias federales o locales
                    </Subtitle_Text>
                    <Paragraph_Text>
                        Como la SEMARNAT, PROFEPA, Estado de México, Ciudad de México, STPS y Municipios.
                    </Paragraph_Text>
                    <ul>
                        <li>
                            <Paragraph_Text>
                                Trámite de licencia de funcionamiento LAU.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Cédula de operación anual COA.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Trámite de inventario de emisiones.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Trámite de residuos peligrosos y no peligrosos.
                            </Paragraph_Text>
                        </li>
                        <li>
                            <Paragraph_Text>
                                Trámite de registro de descarga de agua residual.
                            </Paragraph_Text>
                        </li>
                    </ul>

                    <Subtitle_Text class="mb-4 mt-12">
                        Asesorías
                    </Subtitle_Text>
                    <Paragraph_Text>
                        En cuestiones legales y técnicas para dar cumplimiento a las disposiciones y
                        requerimientos en materia de protección al medio ambiente.
                    </Paragraph_Text>
                </Content_Column>
            </v-row>

            <!-- COMPANY INTRO ROW -->
            <v-row align="center" justify="center" class="px-lg-16 mt-16">
                <!-- COMPANY FULL DESCRIPTION -->
                <Paragraph_Text  class="px-lg-16" style="text-align: center;">
                    Contamos con un excelente equipo de peritos autorizados para ayudarte a implementar, revisar
                    y certificar tus Sistemas de Gestión Ambiental para las empresas.
                </Paragraph_Text>
            </v-row>

            <!-- CONTACT BUTTON -->
            <SingleButton_Row
                button-label="Contáctanos"
                @button-click="goToNextRoute"
                class="mt-12"
            ></SingleButton_Row>
        </Content_Row>
    </div>
</template>

<script>
import Content_Row from "@/components/sections/content/Content_Row";
import Screen_Header from "@/components/sections/headers/Screen_Header";
import Subtitle_Text from "@/components/texts/Subtitle_Text";
import Paragraph_Text from "@/components/texts/Paragraph_Text";
import SingleButton_Row from "@/components/buttons/SingleButton_Row";
import Regulation_Image from "@/components/images/Regulation_Image";
import Content_Column from "@/components/sections/content/Content_Column";

export default {
    name: "Environment_Screen",
    components: {
        Screen_Header,
        Subtitle_Text,
        Content_Row,
        Paragraph_Text,
        SingleButton_Row,
        Regulation_Image,
        Content_Column,
    },
    methods: {
        goToNextRoute() {
            this.$router.push('/contacto');
        }
    }
}
</script>

<style scoped>

</style>