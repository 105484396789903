<template>
    <v-app>
        <Navbar></Navbar>

        <v-main>
            <router-view/>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>

<script>
import './styles/main.css';
import Navbar from "@/components/navbars/Navbar";
import Footer from "@/components/footers/Footer";

export default {
    name: 'App',
    components: {
        Navbar,
        Footer,
    },
};
</script>
