<template>
    <v-footer class="mt-16" color="secondary" padless>
        <v-row id="footer" align="center" class="mx-16 mt-8 mb-12 pt-8" justify="center">
            <v-col>
                <!-- MAIN FOOTER'S CONTENT ROW -->
                <v-row id="navbar-div" class="mx-lg-16" justify="start">
                    <!-- INDEX + LOGO COLUMN -->
                    <v-col class="pa-0" sm="12" xs="12" md="8" lg="8" xl="8">
                        <v-row justify="space-around">
                            <!-- PUNTLI FOOTER LOGO -->
                            <v-col align-self="center" class="pa-0" cols="12" md="3" lg="3" xl="3">
                                <v-row justify="center" align="center">
                                    <v-img
                                        id="navbar-logo"
                                        alt="Puntli"
                                        class="shrink"
                                        max-height="125"
                                        contain
                                        src="../../assets/brand/Puntli Footer.png"
                                        transition="scale-transition"
                                    />
                                </v-row>
                            </v-col>

                            <!-- WEBSITE INDEX -->
                            <v-col class="pa-0 website-index" cols="12" md="9" lg="9" xl="9">
                                <!-- HOME BUTTON -->
                                <v-row>
                                    <router-link class="nav_item mt-1" to="/">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 white--text"
                                            color="secondary"
                                            small
                                        >
                                            Inicio
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- QUALITY BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/calidad">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 white--text"
                                            color="secondary"
                                            small
                                        >
                                            Calidad
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- ENVIRONMENT BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/medioAmbiente">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 white--text"
                                            color="secondary"
                                            small
                                        >
                                            MEDIO AMBIENTE
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- SECURITY BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/seguridad">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 white--text"
                                            color="secondary"
                                            small
                                        >
                                            SEGURIDAD Y SALUD EN EL TRABAJO
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- TRAINING BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/capacitacion">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 white--text"
                                            color="secondary"
                                            small
                                        >
                                            CAPACITACIÓN
                                        </v-btn>
                                    </router-link>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- SOCIAL MEDIA ICONS COLUMN -->
                    <v-col class="pa-0 socialMediaColumn" sm="12" xs="12" md="4" lg="4" xl="4">
                        <v-row id="contactRow" align="start" justify="end">
                            <v-col sm="2" xs="2" md="2" lg="2" xl="2" align="start">
                                <!-- LINKEDIN ICON -->
                                <a href="#" target="_blank">
                                    <v-img
                                        src="../../assets/icons/LK.png"
                                        class="mb-3"
                                        height="33"
                                        width="33"
                                    >
                                    </v-img>
                                </a>

                                <!-- TWITTER ICON -->
                                <a href="https://twitter.com/PuntliM" target="_blank">
                                    <v-img
                                        src="../../assets/icons/Twiter.png"
                                        class="mb-3"
                                        height="33"
                                        width="33"
                                    >
                                    </v-img>
                                </a>

                                <!-- FACEBOOK ICON -->
                                <a href="https://www.facebook.com/Puntlimx-112119134933083" target="_blank">
                                    <v-img
                                        src="../../assets/icons/FB.png"
                                        class="mb-3"
                                        height="33"
                                        width="33"
                                    >
                                    </v-img>
                                </a>
                            </v-col>

                            <v-col sm="10" xs="10" md="7" lg="7" xl="7" align="end">
                                <!-- CONTACT TITLE -->
                                <p class="index-title balooBold contactText">CONTACTO</p>

                                <!-- CONTACT MAIL -->
                                <p class="index-title baloo contactText">
                                    <a href="mailto:contacto@puntli.com" target="_blank">contacto@puntli.com</a>
                                </p>

                                <v-row class="mr-0 mt-1 contactIconsRow">
                                    <!-- PHONE ICON -->
                                    <a href="tel:5518506792" target="_blank">
                                        <v-icon
                                            class="mr-2"
                                            dark
                                            large
                                        >phone
                                        </v-icon>
                                    </a>

                                    <!-- MAP ICON -->
                                    <a href="https://www.google.com/maps/search/?api=1&query=18.9927152,-98.2770322" target="_blank">
                                        <v-icon
                                            class="mr-2"
                                            dark
                                            large
                                        >location_on
                                        </v-icon>
                                    </a>

                                    <!-- MAIL ICON -->
                                    <a href="mailto:contacto@puntli.com" target="_blank">
                                        <v-icon
                                            dark
                                            large
                                        >email
                                        </v-icon>
                                    </a>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!-- COPYRIGHT ROW -->
                <v-row class="mt-16">
                    <!-- HORIZONTAL DIVIDER -->
                    <v-container class="pa-0" fluid>
                        <hr>
                    </v-container>

                    <!-- MEZCLA BRAVA COPYRIGHT LABEL -->
                    <v-row align="center" justify="space-around" class="mt-1">

                        <!-- COPY RIGHT -->
                        <v-col xl="6" lg="6" md="12" sm="12" xs="12">
                            <p class="index-title balooBold copyrightText">
                                <v-icon
                                    dark
                                    class="mt-n1"
                                >copyright
                                </v-icon>
                                Puntli Consultoría Ambiental S.A. de C.V.
                            </p>
                        </v-col>

                        <!-- DEVELOPED BY ideen -->
                        <v-col xl="6" lg="6" md="12" sm="12" xs="12" align="end">
                            <p class="index-title balooBold copyrightText">
                                Desarrollado por

                                <a
                                    href="https://ideen.mx"
                                    target="_blank"
                                    style="text-decoration: underline !important;">ideen</a> | México, 2022.
                            </p>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            privacy: "/files/avisodeprivacidad.pdf"
        }
    }
}
</script>

<style scoped>
#navbar-logo {
    opacity: 1 !important;
    width: 120px;
}

.index-title {
    color: #FFFFFF;
}

.footer-item-label {
    color: #FFF;
    text-decoration: none !important;
    font-size: 15px;
}

a {
    text-decoration: none !important;
    color: white !important;
}

i:hover, a:hover {
    color: #3DAE2B !important;
}

p {
    color: #FFF !important;
}

.contactText {
    text-align: end;
}

.contactIconsRow {
    justify-content: end;
}

@media (max-width: 960px) {
    .website-index {
        margin-top: 50px;
        margin-left: 40px;
    }

    .socialMediaColumn {
        margin-top: 40px;
    }

    #footer {
        margin: 50px 20px !important;
    }

    #navbar-div {
        padding: 0 10px;
    }

    #contactRow {
        margin-top: 10px;
        padding: 0 30px;
    }

    .contactIconsRow {
        justify-content: center;
    }

    .copyrightText {
        font-size: 0.9em;
    }
}
</style>