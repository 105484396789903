<template>
    <div>
        <!-- SCREEN HEADER -->
        <Screen_Header
            header-text="Calidad"
            image-name="Calidad.png"
        ></Screen_Header>

        <Content_Row>
            <!-- QUALITY SUBHEADER ROW -->
            <v-row align="center" justify="center" class="px-lg-8 noSpace">
                <!-- SCREEN SUBTITLE -->
                <v-col cols="12" md="6" class="noSpace">
                    <Subtitle_Text>
                        Sistemas de Gestión de Calidad para las empresas.
                    </Subtitle_Text>
                </v-col>

                <!-- ISO 9001 LOG0 -->
                <v-col cols="12" md="6" align="center" class="noSpace">
                    <Regulation_Image
                        image="ISO 9001.png"
                    ></Regulation_Image>
                </v-col>
            </v-row>

            <!-- QUALITY TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-8 noSpace">
                <Content_Column>
                    <Paragraph_Text>
                        La certificación con la norma ISO 9001 no es una obligación, sino una oportunidad de mejorar
                        a tu empresa. <br><br>
                    </Paragraph_Text>
                    <Paragraph_Text>
                        Organizar de manera eficiente una empresa es posible, solo debemos enfocarnos en la calidad
                        de nuestro sistema de gestión.
                    </Paragraph_Text>
                </Content_Column>
                <Content_Column>
                    <Paragraph_Text>
                        Para certificarse se requiere:
                    </Paragraph_Text>
                    <ul>
                        <li><Paragraph_Text>
                            Desarrollo, implementación y asesoría en Sistemas de Gestión de la Calidad de empresas
                            ISO 9001:2015.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Apoyo para implementar el SGC.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Asesoría en un punto específico del SGC.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Realizar una auditoría a tu SGC.
                        </Paragraph_Text></li>
                    </ul>

                </Content_Column>
            </v-row>
        </Content_Row>

        <!-- SCREEN HEADER -->
        <Screen_Header
            image-name="Calidad 2.png"
        ></Screen_Header>

        <Content_Row>
            <!-- CONTENT SUBTITLE #2 ROW -->
            <v-row align="center" justify="start" class="px-lg-8">
                <v-col cols="12" md="8" class="noSpace">
                    <Subtitle_Text>
                        Sistemas de Gestión de Calidad en laboratorios analíticos, laboratorios clínicos y unidades de inspección.
                    </Subtitle_Text>
                </v-col>
            </v-row>

            <!-- REGULATIONS LOGO ROW -->
            <v-row align="center" justify="start" class="px-lg-8 mt-8">
                <!-- ISO 17025 LOG0 -->
                <v-col cols="12" md="4" align="center">
                    <Regulation_Image
                        image="ISO 17025.png"
                    ></Regulation_Image>
                </v-col>
                <!-- ISO 15189 LOG0 -->
                <v-col cols="12" md="4" align="center">
                    <Regulation_Image
                        image="ISO 15189.png"
                    ></Regulation_Image>
                </v-col>
                <!-- ISO 17020 LOG0 -->
                <v-col cols="12" md="4" align="center">
                    <Regulation_Image
                        image="ISO 17020.png"
                    ></Regulation_Image>
                </v-col>
            </v-row>

            <!-- QUALITY TEXT ROW -->
            <v-row align="center" justify="center" class="px-lg-8 mt-12">
                <!-- LEFT COLUMN -->
                <Content_Column>
                    <Paragraph_Text>
                        Los resultados son la parte más importante en un laboratorio, debemos de cuidarlos. <br><br>
                    </Paragraph_Text>
                    <Paragraph_Text>
                        Los ensayos de aptitud y las reglas de decisión son temas nuevos muy importantes en los
                        laboratorios.
                    </Paragraph_Text>
                </Content_Column>

                <!-- RIGHT COLUMN -->
                <Content_Column class="mt-lg-0 mt-12">
                    <Paragraph_Text>
                        Para acreditarse se requiere:
                    </Paragraph_Text>
                    <ul>
                        <li><Paragraph_Text>
                            Organizar todas las áreas de tu laboratorio, un SGC es la solución.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Que tus resultados sean verificados, validados y cuantificar la incertidumbre.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Aseguramiento de la validez de los resultados.
                        </Paragraph_Text></li>
                        <li><Paragraph_Text>
                            Acreditación en las unidades de inspección con la ISO 17020 para evidenciar la calidad de su gestión.
                        </Paragraph_Text></li>
                    </ul>

                </Content_Column>
            </v-row>

            <!-- COMPANY INTRO ROW -->
            <v-row align="center" justify="center" class="px-lg-16 mt-16">
                <!-- COMPANY FULL DESCRIPTION -->
                <Paragraph_Text  class="px-lg-16" style="text-align: center;">
                    Tenemos el equipo con la experiencia y conocimientos necesarios para ayudarte a implementar,
                    revisar y acreditar tus Sistemas de Gestión de Calidad.
                </Paragraph_Text>
            </v-row>

            <!-- CONTACT BUTTON -->
            <SingleButton_Row
                button-label="Contáctanos"
                @button-click="goToNextRoute"
                class="mt-12"
            ></SingleButton_Row>
        </Content_Row>
    </div>
</template>

<script>
import Content_Row from "@/components/sections/content/Content_Row";
import Screen_Header from "@/components/sections/headers/Screen_Header";
import Subtitle_Text from "@/components/texts/Subtitle_Text";
import Paragraph_Text from "@/components/texts/Paragraph_Text";
import SingleButton_Row from "@/components/buttons/SingleButton_Row";
import Regulation_Image from "@/components/images/Regulation_Image";
import Content_Column from "@/components/sections/content/Content_Column";

export default {
    name: "Quality_Screen",
    components: {
        Screen_Header,
        Subtitle_Text,
        Content_Row,
        Paragraph_Text,
        SingleButton_Row,
        Regulation_Image,
        Content_Column,
    },
    methods: {
        goToNextRoute() {
            this.$router.push('/contacto');
        }
    }
}
</script>

<style scoped>

</style>