<template>
    <v-container>
        <v-icon
            class="mb-6"
            color="primary"
            x-large
        >{{ icon }}
        </v-icon>

        <v-row
            v-for="(link, index) in links"
            :key="index"
            align="center" justify="center"
        >
            <a
                :href="link.url"
                target="_blank"
            >
                <Paragraph_Text class="icon-button-text">
                    {{ link.text }}
                </Paragraph_Text>
            </a>
        </v-row>
    </v-container>
</template>

<script>
import Paragraph_Text from "@/components/texts/Paragraph_Text";

export default {
    components: {
        Paragraph_Text
    },
    name: "Icon_Button",
    props: {
        icon: String,
        links: Array
    }
}
</script>

<style scoped>
.icon-button-text, a {
    color: #888888 !important;
    text-decoration: none !important;
}

.icon-button-text:hover {
    color: #0B6937 !important;
    text-decoration: underline !important;
}
</style>