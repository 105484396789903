<template>
    <div class="subtitle-text baloo">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Subtitle_Text",
}
</script>

<style scoped>
.subtitle-text {
    color: #808080;
    font-size: 1.35em;
}

@media (max-width: 960px) {
    .subtitle-text {
        text-align: center;
    }
}
</style>