<template>
    <v-row align="center" justify="center" class="noSpace pt-16 pb-12">
        <v-col class="noSpace" v-if="this.deviceType === 'desktop'" lg="2"></v-col>
        <v-col class="noSpace pt-8" lg="8" sm="12" :class="deviceType === 'mobile' ? 'px-8' : 'px-0'">
            <slot />
        </v-col>
        <v-col class="noSpace" v-if="this.deviceType === 'desktop'" lg="2"></v-col>
    </v-row>
</template>

<script>
export default {
    name: "Content_Row",
    computed: {
        deviceType() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'mobile'
                case 'sm': return 'mobile'
                case 'md': return 'mobile'
                case 'lg': return 'desktop'
                case 'xl': return 'desktop'
                default: return 'desktop'
            }
        }
    },
}
</script>

<style scoped>

</style>