<template>
    <div class="title-text baloo">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Paragraph_Text",
}
</script>

<style scoped>
.title-text {
    color: #808080;
    font-size: 1em;
    text-decoration: none !important;
}
</style>