import Vue from 'vue'
import VueRouter from 'vue-router'
import Home_Screen from "@/views/Home_Screen";
import Quality_Screen from "@/views/Quality_Screen";
import Environment_Screen from "@/views/Environment_Screen";
import Security_Screen from "@/views/Security_Screen";
import Training_Screen from "@/views/Training_Screen";
import Contact_Screen from "@/views/Contact_Screen";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home_Screen',
    component: Home_Screen,
  },
  {
    path: '/calidad',
    name: 'Quality_Screen',
    component: Quality_Screen,
  },
  {
    path: '/medioAmbiente',
    name: 'Environment_Screen',
    component: Environment_Screen,
  },
  {
    path: '/seguridad',
    name: 'Security_Screen',
    component: Security_Screen,
  },
  {
    path: '/capacitacion',
    name: 'Training_Screen',
    component: Training_Screen,
  },
  {
    path: '/contacto',
    name: 'Contact_Screen',
    component: Contact_Screen,
  },
  {
    path: '/*',
    name: 'Home_Screen',
    component: Home_Screen,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
