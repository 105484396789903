<template>
    <v-container fluid>
        <v-app-bar
            class="desktop-navbar"
            extension-height="10"
            color="white"
            extended
            outlined
            fixed
            dark
            flat
            app
        >
            <v-spacer class="hidden-sm-and-down"></v-spacer>

            <!-- PUNTLI LOGO -->
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-img
                        id="navbar-logo"
                        alt="Puntli"
                        class="shrink mr-2"
                        contain
                        src="../../assets/brand/Puntli NavBar.png"
                        transition="scale-transition"
                    />
                </router-link>
            </div>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <!-- HOME SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    INICIO
                </v-btn>
            </router-link>

            <!-- QUALITY SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/calidad">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    CALIDAD
                </v-btn>
            </router-link>

            <!-- ENVIRONMENT SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/medioAmbiente">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    MEDIO AMBIENTE
                </v-btn>
            </router-link>

            <!-- SECURITY SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/seguridad">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    SEGURIDAD Y SALUD EN EL TRABAJO
                </v-btn>
            </router-link>

            <!-- TRAINING SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/capacitacion">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    CAPACITACIÓN
                </v-btn>
            </router-link>

            <!-- CONTACT SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/contacto">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" plain color="primary" large>
                    CONTACTO
                </v-btn>
            </router-link>

            <!-- MOBILE NAVBAR MENU ICON BUTTON-->
            <v-btn
                @click.stop="drawer = !drawer"
                class="hidden-md-and-up"
                color="primary"
                icon
                fab
            >
                <v-icon x-large>menu</v-icon>
            </v-btn>

            <!-- MOBILE NAVIGATION DRAWER -->
            <v-navigation-drawer
                style="position: fixed; top: 0; right: 0; overflow-y: scroll"
                v-model="drawer"
                color="primary"
                height="100vh"
                width="100%"
                temporary
                absolute
                right
                dark
                app
            >
                <v-col align-self="end" align="end">
                    <!-- MOBILE NAVBAR MENU ICON BUTTON-->
                    <v-row justify="end" align="center">
                        <v-btn
                            class="hidden-md-and-up mt-2 mr-1 mb-6"
                            color="white"
                            fab
                            icon
                            @click.stop="drawer = !drawer"
                        >
                            <v-icon x-large>close</v-icon>
                        </v-btn>
                    </v-row>

                    <!-- HOME SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                INICIO
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- QUALITY SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/calidad">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                CALIDAD
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- ENVIRONMENT SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/medioAmbiente">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                MEDIO AMBIENTE
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- SECURITY SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/seguridad">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                SEGURIDAD Y SALUD EN EL TRABAJO
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- TRAINING SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/capacitacion">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                CAPACITACIÓN
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- CONTACT SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/contacto">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="primary" large>
                                CONTACTO
                            </v-btn>
                        </router-link>
                    </v-row>
                </v-col>
            </v-navigation-drawer>

            <v-spacer class="hidden-sm-and-down"></v-spacer>

        </v-app-bar>
    </v-container>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            productsMenu: null,
            drawer: false,
        }
    },
}
</script>

<style scoped>

.desktop-navbar {
    padding-top: 10px;
    padding-bottom: 75px;
}

.desktop-navbar:hover {
    opacity: 1;
}

#navbar-logo {
    width: 120px;
}

.v-application a {
    text-decoration: none !important;
}

@media (max-width: 960px) {
    .desktop-navbar {
        padding-top: 5px;
        padding-bottom: 10px;
    }

    #navbar-logo {
        width: 100px;
    }

    .navbar-item-label {
        margin-bottom: 5px;
    }
}
</style>