<template>
    <v-row align="center" justify="center" no-gutters class="noSpace">
        <v-col lg="4" cols="1" class="noSpace"></v-col>
        <v-col lg="4" cols="10" class="noSpace">
            <Block_Button
                :button-label="buttonLabel"
                @button-click="clickButton"
            ></Block_Button>
        </v-col>
        <v-col lg="4" cols="1" class="noSpace"></v-col>
    </v-row>
</template>

<script>
import Block_Button from "@/components/buttons/Block_Button";

export default {
    props: {
        buttonLabel: String,
    },
    name: "SingleButton_Row",
    components: {
        Block_Button,
    },
    methods: {
        clickButton() {
            this.$emit('button-click');
        }
    }
}
</script>

<style scoped>

</style>